/* Generated by Font Squirrel (http://www.fontsquirrel.com) on December 9, 2011 */

@font-face {
    font-family: 'MgOpen Moderna';
    

    src: local(MgOpen Moderna), local(MgOpen-Moderna), url('mgopenmodernaregular-webfont.ttf') format('truetype');

    src: url('mgopenmodernaregular-webfont.eot');
    src: url('mgopenmodernaregular-webfont.eot?#iefix') format('embedded-opentype'),
         url('mgopenmodernaregular-webfont.woff') format('woff'),
         url('mgopenmodernaregular-webfont.svg#MgOpenModernaRegular') format('svg');
      
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'MgOpen Moderna';
    src: local(MgOpen Moderna Bold), local(MgOpen-Moderna-Bold), url('mgopenmodernabold-webfont.ttf') format('truetype');
    
    src: url('mgopenmodernabold-webfont.eot');
    src: url('mgopenmodernabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('mgopenmodernabold-webfont.woff') format('woff'),
         url('mgopenmodernabold-webfont.svg#MgOpenModernaBold') format('svg');
      
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'MgOpen Moderna';
    src: local(MgOpen Moderna Oblique), local(MgOpen-Moderna-Oblique), url('mgopenmodernaoblique-webfont.ttf') format('truetype');
    src: url('mgopenmodernaoblique-webfont.eot');
    src: url('mgopenmodernaoblique-webfont.eot?#iefix') format('embedded-opentype'),
         url('mgopenmodernaoblique-webfont.woff') format('woff'),
         url('mgopenmodernaoblique-webfont.svg#MgOpenModernaOblique') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'MgOpen Moderna';
    src: local(MgOpen Moderna Bold Oblique), local(MgOpen-Moderna-Bold-Oblique) url('mgopenmodernaboldoblique-webfont.ttf') format('truetype');
    src: url('mgopenmodernaboldoblique-webfont.eot');
    src: url('mgopenmodernaboldoblique-webfont.eot?#iefix') format('embedded-opentype'),
         url('mgopenmodernaboldoblique-webfont.woff') format('woff'),
         url('mgopenmodernaboldoblique-webfont.svg#MgOpenModernaBoldOblique') format('svg');
    font-weight: bold;
    font-style: italic;
}
