@import url("fonts/roboto/stylesheet.css");
@import url("fonts/lato/stylesheet.css");
@import url("fonts/klavika/stylesheet.css");
@import url("fonts/liberationsans/stylesheet.css");
@import url("fonts/liberationsans-narrow/stylesheet.css");
@import url("fonts/mgopenmoderna/stylesheet.css");
@import url("fonts/inter/inter.css");

@tailwind base;
@tailwind components;
@tailwind utilities;